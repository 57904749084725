<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Contexte général"
        :progress_section="progress_section.contexte_general"
      />
    </template>
    <template #current_page>Contexte général </template>
    <template #body>
      <div class="flex">
        <span class="text-promy-green-200">
          <i class="fas fa-exclamation-triangle"></i> Remplacer les mots entre
          crochet par leur valeurs
        </span>
      </div>
      <div class="flex">
        <ValidationObserver
          v-slot="{ handleSubmit }"
          class="w-full"
          ref="formInformations"
        >
          <form
            @submit.prevent="handleSubmit()"
            class="w-full flex flex-col mt-6 contexte-generale-form"
          >
            <div class="flex w-full mb-15">
              <div class="descriptif-bati flex flex-col lg:w-full xl:w-full">
                <pro-input
                  classesInput="w-fit"
                  shouldBeHighlighted
                  name=""
                  requirements="required"
                  v-model="contexte_general.titre_context_content"
                  label=""
                />

                <div class="flex w-6/10 lg:w-full">
                  <quill-editor
                    ref="myQuillEditor"
                    class="w-full"
                    v-model="contexte_general.context_content"
                  />
                </div>
                <HighlightMessage
                  classes="w-6/10 relative top-13"
                  :show="isEmptyOrNull(contexte_general.context_content)"
                />
                <!-- Promy est mandatée par [entreprise qui cherche acquéreur] pour lui trouver acquéreur afin de réaliser un projet immobilier sur [ endroit et condition pour le projet immobilier ] . -->
              </div>
            </div>

            <div class="grid grid-cols-4 lg:grid-cols-1 gap-2 mt-10">
              <pro-input
                shouldBeHighlighted
                name="N° mandat"
                requirements="required"
                v-model="contexte_general.n_mandat"
                label="N° mandat"
                type="number"
              />
              <span>
                <label
                  class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                >
                  Date signature
                </label>
                <datepicker
                  class="date-input pt-1 mb-5"
                  v-model="contexte_general.date_signature_mandat"
                  valueType="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                />
                <HighlightMessage
                  :show="isEmptyOrNull(contexte_general.date_signature_mandat)"
                />
              </span>
              <pro-input
                name="N° délégation"
                v-model="contexte_general.n_delegation"
                label="N° de délégation"
                type="number"
              />
              <span>
                <label
                  class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                >
                  Date signature
                </label>
                <datepicker
                  class="date-input pt-1 mb-5"
                  v-model="contexte_general.date_signature_delegation"
                  valueType="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                />
              </span>
            </div>

            <div class="flex w-full my-15">
              <div class="descriptif-bati flex flex-col lg:w-full xl:w-full">
                <pro-input
                  classesInput="w-fit"
                  shouldBeHighlighted
                  name=""
                  requirements="required"
                  v-model="contexte_general.titre_situation_juridique"
                  label=""
                />
                <div class="flex w-6/10 lg:w-full">
                  <quill-editor
                    ref="myQuillEditor"
                    class="mb-12 w-full"
                    v-model="contexte_general.situation_juridique"
                  />
                </div>
                <HighlightMessage
                  classes="w-6/10 relative top-3"
                  :show="isEmptyOrNull(contexte_general.situation_juridique)"
                />
              </div>
            </div>

            <div class="descriptif-bati flex flex-col lg:w-full xl:w-3/4 mt-8">
              <div
                class="flex items-baseline mb-4 space-x-2 lg:items-end"
                v-for="(item, key) in contexte_general.descriptif_bati"
                :key="key"
              >
                <div class="w-4/12">
                  <div class="flex flex-col">
                    <pro-input
                      shouldBeHighlighted
                      placeholder="exemple (maison à usage d'habitation, annexes)"
                      class=""
                      name="type "
                      requirements="required"
                      v-model="item.type"
                      label="type "
                    />
                  </div>
                </div>
                <div class="w-3/12">
                  <div class="flex flex-col">
                    <pro-input
                      shouldBeHighlighted
                      name="surface"
                      requirements="required"
                      type="number"
                      v-model="item.surface_habitable"
                      label="surface"
                    />
                  </div>
                </div>
                <div class="w-3/12">
                  <div class="flex flex-col">
                    <pro-input
                      shouldBeHighlighted
                      name="Surface Terrain"
                      requirements="required"
                      type="number"
                      v-model="item.surface_terrain"
                      label="surface terrain"
                    />
                  </div>
                </div>
                <div class="w-4/12">
                  <div class="flex flex-col">
                    <vue-select
                      :data="etats"
                      v-model="item.etat"
                      v-on:valueSelect="onValueSelect($event, key)"
                    >
                      <template v-slot:SelectLabel>Etat</template>
                    </vue-select>
                    <HighlightMessage :show="isEmptyOrNull(item.etat)" />
                  </div>
                </div>
                <div
                  class="flex self-center w-2/12 mt-4"
                  v-if="contexte_general.descriptif_bati.length > 1"
                >
                  <span
                    @click="deleteBatiExistant(key)"
                    class="text-promy-red ml-2 cursor-pointer"
                  >
                    <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
                  </span>
                </div>
              </div>
              <div
                class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
                @click="addBatiExistant"
              >
                <p>Ajouter d'autre champs</p>
                <span
                  class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
                >
                  <i class="fa fa-plus text-xs" />
                </span>
              </div>
            </div>

            <div class="timeline flex flex-col lg:w-full xl:w-3/4 mt-8">
              Timeline
              <div
                class="flex items-baseline mb-4 space-x-2 lg:items-end"
                v-for="(item, key) in contexte_general.timelines"
                :key="key"
              >
                <div class="w-6/12">
                  <div class="flex flex-col">
                    <h3
                      class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                    >
                      Descirpition
                    </h3>
                    <quill-editor
                      ref="myQuillEditor"
                      class="w-full mt-2"
                      v-model="item.description"
                    />
                  </div>
                </div>
                <div class="w-3/12">
                  <span>
                    <label
                      class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                    >
                      Date
                    </label>
                    <datepicker
                      class="date-input pt-1 mb-5"
                      v-model="item.date"
                      valueType="DD-MM-YYYY"
                      format="DD-MM-YYYY"
                    />
                    <HighlightMessage :show="isEmptyOrNull(item.date)" />
                  </span>
                </div>
                <div class="w-3/12">
                  <div class="flex flex-col">
                    <h3
                      class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                    >
                      Logo
                    </h3>
                    <InputImage
                      :file="item.logo"
                      :selfUploader="true"
                      @upload="updateImage($event, key)"
                      @deleteImage="deleteImage(key)"
                    />
                  </div>
                </div>
                <div
                  class="flex self-center w-2/12 mt-4"
                  v-if="contexte_general.timelines.length > 1"
                >
                  <span
                    @click="deleteTimelineExistant(key)"
                    class="text-promy-red ml-2 cursor-pointer"
                  >
                    <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
                  </span>
                </div>
              </div>
              <div
                class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
                @click="addTimelineExistant"
              >
                <p>Ajouter d'autre champs</p>
                <span
                  class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
                >
                  <i class="fa fa-plus text-xs" />
                </span>
              </div>
            </div>

            <div class="flex w-full my-15 sm:mb-14">
              <div class="descriptif-bati flex flex-col lg:w-full xl:w-full">
                <pro-input
                  classesInput="w-fit"
                  shouldBeHighlighted
                  name=""
                  requirements="required"
                  v-model="contexte_general.titre_conditions_particulieres"
                  label=""
                />
                <div class="flex w-6/10 lg:w-full">
                  <quill-editor
                    ref="myQuillEditor"
                    class="w-full"
                    v-model="contexte_general.conditions_promoteurs"
                  />
                </div>
                <HighlightMessage
                  classes="w-6/10 relative top-13"
                  :show="isEmptyOrNull(contexte_general.conditions_promoteurs)"
                />
              </div>
            </div>

            <div class="flex w-full my-15 sm:mb-14">
              <div class="w-full flex flex-col">
                <h2
                  class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                >
                  Condition Suspensive
                </h2>
                <div class="space-y-2 mt-4">
                  <div
                    v-for="(condition, idx) in conditionSuspensive"
                    class="flex flex-col"
                    :key="idx"
                  >
                    <div class="flex items-center justify-between w-full">
                      <label
                        :for="condition.nom"
                        class="text-sm font-medium text-promy-grey-100 w-full"
                      >
                        {{ condition.nom }}
                      </label>
                      <ProToggle
                        :isSet="isConditionInForm(condition)"
                        class="w-full"
                        v-model="contexte_general.conditions"
                        :id="condition.nom"
                        :val="condition"
                        @change="addCondition"
                        labelDescription=""
                      />
                    </div>

                    <pro-textarea
                      v-if="
                        isConditionInForm(condition) &&
                        condition.with_other_condition
                      "
                      class=""
                      v-model="contexte_general.conditions[idx].other_condition"
                      label=""
                      :placeholder="'Autre condition'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'
import ProToggle from '../../components/UI/Fileds/ProToggle.vue'

export default {
  mixins: [progress],

  directives: {
    onClickaway: onClickaway,
  },

  data() {
    return {
      contexte_general: {
        conditions: [],
        titre_context_content: 'Contexte général',
        titre_situation_juridique: 'Situation juridique',
        titre_descriptif_bati: 'Bâti(s) existant',
        titre_conditions_particulieres: 'Conditions particulières',
        context_content: null,
        situation_juridique: null,
        descriptif_bati: [
          {
            type: '',
            surface_habitable: '',
            surface_terrain: '',
            etat: '',
          },
          {
            type: '',
            surface_habitable: '',
            surface_terrain: '',
            etat: '',
          },
        ],
        timelines: [],
        conditions_promoteurs: null,
        n_mandat: null,
        date_signature_mandat: null,
        n_delegation: null,
        date_signature_delegation: null,
      },
      etats: [
        'Mauvais',
        'Moyen',
        'Bon',
        'A rénover',
        'Viabilisé',
        'Non viabilisé',
        'En cours de viabilisation',
        'En état',
        'A défraichir',
      ],
    }
  },
  watch: {
    contexte_general: {
      deep: true,
      handler() {
        this.excluded_keys.push('n_delegation')
        this.excluded_keys.push('date_signature_delegation')
        this.excluded_objs.push('conditions')
        this.excluded_objs.push('timelines')

        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'contexte_general',
          value: this.progressSection(this.contexte_general),
        })
      },
    },
  },
  computed: {
    ...mapGetters({
      conditions: 'dossierpromoteur/getConditions',
      conditionSuspensive: 'dossierpromoteur/getConditionSuspensive',
      ContexteGeneraleStore: 'dossierpromoteur/getContexteGenerale',
      timelineStore: 'dossierpromoteur/getTimeline',
      progress_section: 'dossierpromoteur/getProgressSection',
      informationsStore: 'dossierpromoteur/getinfos',
    }),
  },

  created() {
    this.contexte_general = Object.assign(
      {},
      this.contexte_general,
      this.ContexteGeneraleStore,
    )
    this.fetchTimeline()
  },
  methods: {
    fetchTimeline() {
      if (this.contexte_general.timelines.length === 0) {
        this.$http.get('admin/timelines').then((response) => {
          this.contexte_general.timelines = response.data
        })
      } else if (this.contexte_general.timelines.length != 0) {
        this.contexte_general.timelines = this.contexte_general.timelines
      } else {
        this.contexte_general.timelines = [
          {
            date: '',
            description: '',
            logo: '',
          },
          {
            date: '',
            description: '',
            logo: '',
          },
        ]
      }
    },
    isConditionInForm(condtion) {
      return this.contexte_general.conditions.some(
        (el) => el.id === condtion.id,
      )
    },
    addCondition(val) {
      let idx = this.contexte_general.conditions.indexOf(val)
      if (idx > -1) {
        this.contexte_general.conditions.splice(idx, 1)
      } else {
        this.contexte_general.conditions.push(val)
      }
    },
    updateImage(file, index) {
      this.contexte_general.timelines[index].logo = file
    },
    deleteImage(index) {
      this.contexte_general.timelines[index].logo = ''
    },
    isAppelAProjet() {
      return this.informationsStore.type_bien === 'Appel à projet'
    },
    saveInfos() {
      this.$store.commit(
        'dossierpromoteur/setContexteGenerale',
        this.contexte_general,
      )
    },
    onValueSelect(value, key) {
      this.contexte_general.descriptif_bati[key].etat = value
    },
    addBatiExistant() {
      this.contexte_general.descriptif_bati.push({
        type: '',
        surface_habitable: '',
        surface_terrain: '',
        etat: '',
      })
    },
    addTimelineExistant() {
      this.contexte_general.timelines.push({
        description: '',
        date: '',
        logo: '',
      })
    },
    deleteBatiExistant(key) {
      this.contexte_general.descriptif_bati.splice(key, 1)
    },
    deleteTimelineExistant(key) {
      this.contexte_general.timelines.splice(key, 1)
    },
    isEmptyOrNull(data) {
      return data === '' || data === null
    },
  },

  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
